html.js {
  .main-sidebar {
    &.show-splash {
      .splash {
        cursor: pointer;
      }
    }

    .main-menu {
      cursor: pointer;

      &.open {
        cursor: default;
      }
    }
  }
}
