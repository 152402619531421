@import '../constants';
@import '../type';
@import '../../lib/type_mixins';

$panel-desktop-inner: $left-panel-inner - $panel-padding * 2;

.qanda-page {
  .panel.panel-left {
    color: $orange;

    @include desktop-and-tablet {
      background-color: transparent;
    }

    @include phone {
      background-color: $light-grey !important;
    }

    html.no-js & {
      @include desktop-and-tablet {
        background-color: $light-grey;
      }
    }

    // Q and A has different panel style, basically we reduce the padding on
    // the right and move the max-width from .panel-inner to .block
    .panel-outer {
      @include desktop-and-tablet {
        padding-right: percentage($panel-padding / $left-panel-inner);
      }

      .panel-inner {
        @include clearfix;

        @include desktop-and-tablet {
          max-width: 100%;
        }
      }
    }
  }

  .picture.phone-only {
    $panel-phone-design-inner: $phone-design - $main-hamburger-phone-width;
    $panel-phone-padding: 20px;
    $panel-phone-portrait-inner: $phone-portrait - $main-hamburger-phone-width;

    @include desktop-and-tablet {
      display: none;
    }

    @include phone {
      $inner: $panel-phone-design-inner - $panel-phone-padding * 2;
      width: percentage($panel-phone-design-inner / $inner);
      margin-left: percentage(-$panel-phone-padding / $inner);
      margin-bottom: $small-margin;
    }
  }

  h1 {
    @include signifier-light(90px);
    line-height: 1;

    @include desktop-and-tablet {
      width: $panel-desktop-inner / 90px * 1em;
      min-height: 60vh;
      margin: 7vh 0;
    }

    @include phone {
      margin: 4vh 0 10vh;
    }
  }

  h2 {
    @include desktop-and-tablet {
      @include h1-style;
      width: $panel-desktop-inner / 54px * 1em;
      color: $light-grey;
      margin-top: 2em;
      min-height: 16vh;
    }

    @include phone {
      @include mager($standard-font-size);
      margin: 0 0 $small-margin;
    }
  }

  .block {
    $block-step: 40px;
    $block-font-size: 18px;
    clear: both;
    font-size: $block-font-size / $standard-font-size * 1em;
    $min-font-size: 13px;
    $stop-shrinking-at: round($min-font-size / $block-font-size * $desktop-design);

    @media screen and (max-width: $stop-shrinking-at) {
      font-size: $min-font-size;
    }

    &.left,
    &.quote {
      @include desktop-and-tablet {
        float: left;
        max-width: percentage(($panel-desktop-inner - $block-step) / $panel-desktop-inner);
      }
    }

    &.left {
      @include desktop-and-tablet {
        margin-left: $block-step / $block-font-size * 1em;
        width: ($panel-desktop-inner - ($block-step * 3)) / $block-font-size * 1em;

        @media screen and (max-width: $stop-shrinking-at) {
          margin-left: percentage($block-step / $panel-desktop-inner);
        }
      }
    }

    &.quote {
      $quote-font-size: 60px;
      $quote-mark-outdent: 30px;
      font-size: $quote-font-size / $standard-font-size * 1em;

      @include desktop-and-tablet {
        margin-left: $block-step / $quote-font-size * 1em;
        width: ($panel-desktop-inner - $block-step) / $quote-font-size * 1em;

        @media screen and (max-width: $stop-shrinking-at) {
          margin-left: percentage($block-step / $panel-desktop-inner);
        }
      }

      p {
        @include signifier-light;
        line-height: 1.08em;
        margin-top: 1.5em;
        $quote-em-width: 0.42em;

        &:first-child {
          @include desktop-and-tablet {
            $text-indent: $quote-mark-outdent / $quote-font-size * 1em;
            $spacing-adjust: $text-indent - $quote-em-width;
            text-indent: -$text-indent;

            &::first-letter {
              letter-spacing: $spacing-adjust;
              margin-right: -$spacing-adjust;
            }
          }

          @include phone {
            $text-indent: $quote-mark-outdent / $quote-font-size * 1em;
            $spacing-adjust: $text-indent - $quote-em-width;
            text-indent: -$text-indent;

            &::first-letter {
              letter-spacing: $spacing-adjust;
              margin-right: -$spacing-adjust;
            }
          }
        }
      }
    }

    &.right {
      @include desktop-and-tablet {
        float: right;
        width: ($panel-desktop-inner - ($block-step * 3)) / $block-font-size * 1em;
        max-width: percentage(($panel-desktop-inner - $block-step) / $panel-desktop-inner);
      }
    }

    p {
      @include signifier-regular;
      margin: 0 0 1.5em;
      line-height: 1.5;

      &.heading {
        @include signifier-medium;
        margin-bottom: .75em;

        + p {
          text-indent: 1.5em;
        }
      }
    }
  }
}
