@import '../constants';
@import '../type';

.standard-form {
  @include clearfix;

  input[type='button'],
  input[type='color'],
  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='file'],
  input[type='image'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='reset'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  textarea,
  select {
    padding-top: .75em;
    padding-bottom: .75em;
  }

  .form-field {
    @include small-text-style;

    &.submit {
      float: right;
    }

    .errorlist {
      margin: 0 0 .25em;
    }
  }

  .input-wrap,
  .select-wrap,
  button,
  textarea {
    border: 1px solid $orange;
    width: 100%;
  }

  // Can't have a pseudo-element on a submit input, so we use a wrapper.
  .submit-wrap {
    @include standard-text-style;
    @include arrow-bullet($standard-font-size);
    border: 0;
    margin: 0;
    width: auto;

    input {
      @include default-anchor-style($offset: 0);
      padding: 0 0 .15em;
    }
  }

  label {
    display: block;
    margin: 0 0 .25em;
  }
}

// Salesforce / activehosted form. All form elements are inputs, except the
// submit which is a button. Style accordingly using their classes so the form
// can be more readily replaced. You may still need to make some tweaks eg.
// adding placeholders
._form {
  ._form_element,
  ._button-wrapper {
    @include small-text-style;

    ._error {
      @include small-text-style;
      line-height: 1;
      color: $black;
      margin: .25em 0 0;
    }
  }

  ._field-wrapper {
    width: 100%;

    input {
      width: 100%;
      border: 1px solid $orange;
      padding-top: .75em;
      padding-bottom: .75em;
    }
  }

  ._button-wrapper {
    @include arrow-bullet($standard-font-size);
    border: 0;
    margin: 0;
    width: auto;
    float: right;

    button[type='submit'] {
      @include default-anchor-style($offset: 0);
      padding: 0 0 .15em;
    }
  }
}
