@import 'c/main/src/constants';
@import '../type';

.site-page {
  .main-sidebar.menu-open ~ #content & {
    cursor: pointer;
  }

  .panel {
    &.fade-panel {
      .panel-outer {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.hide-panel {
      @include desktop-and-tablet {
        &.panel-left {
          transform: translateX(-100%);
        }

        &.panel-right {
          transform: translateX(100%);
        }
      }

      @include phone {
        transform: translateX(-100%);
      }
    }

    &.phone-incoming {
      @include phone {
        transform: translateX(-100%);
      }
    }

    .seo-page-title {
      position: absolute;
      bottom: 100%;
      left: 0;
    }
  }
}

.products-nav,
.services-nav {
  ul {
    margin: 0;

    li {
      @include small-text-style;
      @include arrow-bullet($standard-font-size);

      @include desktop-and-tablet {
        margin-bottom: 1.5em;
      }

      @include phone {
        margin-bottom: 1.25em;
      }

      .title {
        @include underline($offset: 0);
        display: inline-block;
        padding-bottom: .15em;
        margin-bottom: .3em;
      }

      a {
        @include hover {
          .title {
            @include clear-anchor-style;
          }
        }
      }
    }
  }
}
