@import 'c/main/src/constants';
@import 'c/main/src/type';

$panel-desktop-inner: $right-panel-inner - $main-hamburger-desktop-width - $panel-padding;

.about-page,
.contact-page {
  .panel {
    background: $light-grey;
    color: $orange;
  }
}

.about-page {
  .panel.panel-right .panel-outer {
    @include desktop-and-tablet {
      // Make sure user can scroll all the way down since staff portraits are
      // revealed on scroll
      padding-bottom: 90vh;
    }
  }

  h1 {
    @include h1-style;
  }

  .picture {
    margin: 0 0 1.25em;
  }

  .about-intro {
    @include phone {
      display: flex;
      flex-direction: column;
    }

    .picture {
      @include phone {
        order: 2;
      }
    }

    p {
      @include phone {
        order: 3;
      }

      &:first-of-type {
        @include phone {
          order: 1;
        }
      }
    }
  }

  .phone-only {
    @include desktop-and-tablet {
      position: absolute;
      bottom: 100%;
      left: 0;
    }
  }

  .staff {
    @include desktop-and-tablet {
      margin: ($large-margin / $standard-font-size * 1em) 0
              (($large-margin * 2) / $standard-font-size * 1em);
    }

    @include phone {
      margin: $medium-margin 0;
    }

    h2 {
      @include h1-style;

      @include desktop-and-tablet {
        margin-bottom: (-$large-margin / $h1-font-size * 1em);
      }
    }

    ul {
      margin: 0;
    }

    .staff-member {
      @include standard-text-style;

      @include desktop-and-tablet {
        padding: (($large-margin * 2) / $standard-font-size * 1em) 0 0;
      }

      @include phone {
        margin: $medium-margin 0 0;
      }

      .picture {
        width: 85%;
        margin: 0 0 ($small-text-size / $standard-font-size * 1em);

        &.phone-only {
          @include desktop-and-tablet {
            display: none;
          }
        }
      }

      .name,
      .position {
        margin: 0 0 ($small-text-size / $standard-font-size * 1em);
      }

      .bio {
        font-size: $small-text-size / $standard-font-size * 1em;

        &::after {
          @include standard-text-style;

          @include desktop-and-tablet {
            display: none;
          }

          @include phone {
            @include underline;
            content: 'Close';
            display: inline-block;
            cursor: pointer;
            margin: 0;
          }
        }

        &.collapsed {
          &::after {
            content: 'Profile';
          }

          .bio-outer {
            @include phone {
              max-height: 0 !important;
            }
          }

          &::after {
            @include phone {
              visibility: inherit;
              opacity: 1;
            }
          }
        }

        .bio-outer {
          @include phone {
            overflow: hidden;
            transition: max-height 500ms;
          }
        }

        .bio-inner {
          padding-bottom: 1em;
        }

        p {
          // nested, so gets bumped down twice
          font-size: $small-text-size / $standard-font-size * 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .contact {
    h2 {
      @include standard-text-style;
      @include arrow-bullet($standard-font-size);
      margin: 0 0 1.5em;

      a {
        @include default-anchor-style;
        padding-bottom: .15em;
      }
    }
  }
}

.contact-page {
  &.success {
    h1,
    .intro,
    ._form,
    .appointment-form {
      display: none;
    }

    .thanks {
      display: block;
    }
  }

  .thanks {
    display: none;
  }

  ._form,
  .appointment-form {
    @include desktop-and-tablet {
      padding-top: ($large-margin / $standard-font-size * 1em);
      padding-right: (($nav-closed-width - $main-hamburger-desktop-width) / $standard-font-size * 1em);
    }

    @include phone {
      padding-top: $medium-margin;
    }

    label {
      display: none;
    }
  }

  .appointment-request {
    @include standard-text-style;
    @include arrow-bullet($standard-font-size);

    a {
      @include default-anchor-style;
    }
  }

  .contact-details,
  .appointment-request {
    @include desktop-and-tablet {
      margin: ($large-margin / $standard-font-size * 1em) 0 0;
    }

    @include phone {
      margin: $medium-margin 0 0;
    }

    a {
      padding-bottom: .15em;
    }
  }
}

.clients-page {
  $clients-desktop-inner: $left-panel-width - $nav-closed-width - $panel-padding * 2;

  .panel {
    background: $pale-pink;
    color: $orange;
  }

  .panel-inner {
    @include phone {
      display: flex;
      flex-direction: column;
    }

    > .cms-html,
    .categories,
    .testimonials,
    .products-nav {
      @include phone {
        order: 2;
      }
    }
  }

  .clients-page-title {
    @include standard-text-style;

    @include desktop-and-tablet {
      margin: ($large-margin / $standard-font-size * 1em) 0 ($medium-margin / $standard-font-size * 1em);
    }

    @include phone {
      @include h1-style;
      order: 1;
    }
  }

  .categories {
    @include desktop-and-tablet {
      margin: 0 0 ($large-margin / $standard-font-size * 1em);
    }

    @include phone {
      margin: $medium-margin 0;
    }

    .category {
      @include desktop-and-tablet {
        margin: 0 0 ($medium-margin / $standard-font-size * 1em);
      }

      @include phone {
        margin: 0 0 $small-margin;
      }

      h2 {
        @include standard-text-style;
        margin-bottom: .25em;
        line-height: 1;
      }

      .cms-text {
        @include small-text-style;

        p {
          // double small
          @include small-text-style;
          margin: 1em 0;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .testimonials {
    .testimonial {
      @include desktop-and-tablet {
        margin-bottom: ($large-margin / $standard-font-size * 1em);
      }

      @include phone {
        margin-bottom: $medium-margin;
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin: 0 0 1.25em;
      }

      .attribution {
        @include mager(30px);
        line-height: 1.2;
        margin: 0;
      }
    }
  }

  .products-nav,
  .services-nav {
    @include desktop-and-tablet {
      margin: ($large-margin / $standard-font-size * 1em) 0 0;
    }

    @include phone {
      margin: $medium-margin 0 0;
    }
  }
}

.careers-page {
  .panel {
    background: $light-grey;
    color: $orange;
  }

  .careers-page-header {
    @include clearfix;

    @include desktop-and-tablet {
      margin-top: 0;
      margin-bottom: ($large-margin / $standard-font-size * 1em);
    }

    @include phone {
      margin-bottom: $medium-margin;
    }

    h1 {
      @include h1-style;

      @include desktop-and-tablet {
        margin: 0;
      }

      @include phone {
        margin: 0;
      }
    }
  }

  .cms-html {
    // Everything in here gets scaled down slightly for this page
    font-size: 0.875em;

    @include desktop-and-tablet {
      margin-top: ($large-margin / $standard-font-size * 1em);
    }

    @include phone {
      margin-top: $medium-margin;
    }
  }
}
