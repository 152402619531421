@import 'c/main/src/constants';
@import 'c/main/src/type';

.error-page {
  h1 {
    @include signifier-light(100px);
    line-height: 1;
    margin: 0;
  }

  p {
    @include signifier-light(36px);
    margin: 2em 0 0;
  }

  .panel {
    color: $orange;
    background-color: transparent;
  }
}
