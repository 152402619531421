@import '../constants';
@import '../type';

.product-page,
.service-page {
  .panel {
    background: $light-grey;
    color: $orange;
  }

  .product-page-header,
  .service-page-header {
    @include clearfix;

    h1,
    h2,
    p {
      &:last-child {
        margin-bottom: 0;
      }

      a {
        @include inverse-anchor-style;
      }
    }

    .phone-only {
      @include desktop-and-tablet {
        display: none;
      }
    }
  }
}

.product-index,
.services-index {
    .product-page-header,
    .service-page-header {
      @include desktop-and-tablet {
        margin-bottom: ($large-margin / $standard-font-size * 1em);
      }

      @include phone {
        margin-bottom: $medium-margin;
      }

      h1,
      h2,
      p {
        @include standard-text-style;
      }
    }

  .products-nav,
  .services-nav {
    @include desktop-and-tablet {
      margin: ($large-margin / $standard-font-size * 1em) 0;
    }

    @include phone {
      margin: $medium-margin 0 0;
    }
  }
}

.product-detail,
.service-detail {
  .product-page-header,
  .service-page-header {
    max-width: 394px / $standard-font-size * 1em;

    @include desktop-and-tablet {
      margin-bottom: $medium-margin / $standard-font-size * 1em;
    }

    @include phone {
      margin-bottom: $small-margin;
    }

    h1 {
      @include h1-style;
    }

    h2,
    p {
      @include standard-text-style;
    }

    h2 {
      @include desktop-and-tablet {
        margin: 0 0 $large-margin / $standard-font-size * 1em;
      }

      @include phone {
        margin: 0 0 $medium-margin;
      }
    }

    p {
      @include phone {
        margin: 0 0 1.75em;
      }
    }
  }

  .product-logo {
    display: block;
    height: 220px / $standard-font-size * 1em;
    width: auto;
    max-width: 100%;
  }

  .cms-html {
    @include desktop-and-tablet {
      margin-top: ($large-margin / $standard-font-size * 1em);
    }

    @include phone {
      margin-top: $medium-margin;
    }
  }

  .free-trial {
    p {
      padding-left: $bullet-spacing / $standard-font-size * 1em;
    }
  }

  .products-nav,
  .services-nav {
    @include desktop-and-tablet {
      margin-top: ($large-margin / $standard-font-size * 1em);
    }

    @include phone {
      margin-top: $medium-margin;
    }
  }
}

.free-trial {
  @include phone {
    margin: $medium-margin 0 0;
  }

  p {
    a {
      padding-bottom: .15em;
    }
  }
}
