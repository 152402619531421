//
// Font-faces
//
@mixin webfont($name, $filename, $weight: 400, $style: normal) {
  // sass-lint:disable-all
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    src: url('./' + $filename + '.eot');
    src: url('./' + $filename + '.eot?#iefix') format('embedded-opentype'),
         url('./' + $filename + '.woff2') format('woff2'),
         url('./' + $filename + '.woff') format('woff'),
         url('./' + $filename + '.ttf') format('truetype'),
         url('./' + $filename + '.svg') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

// Yes, this is correct. Signifier and Zerfallen seem to be the same thing
@include webfont('signifier', 'ZerfallenWeb-Light', 300);
@include webfont('signifier', 'ZerfallenWeb-Regular', 400);
@include webfont('signifier', 'SignifierWeb-RegularItalic', 400, italic);
@include webfont('signifier', 'ZerfallenWeb-Medium', 500);

@include webfont('kraftig', 'SohneWeb-Kraftig', 400);
@include webfont('kraftig', 'SohneWeb-KraftigKursiv', 400, italic);
@include webfont('mager', 'SohneWeb-Mager', 400);
@include webfont('mager', 'SohneWeb-MagerKursiv', 400, italic);
