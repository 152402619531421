@import 'c/main/src/constants';
@import 'c/main/src/type';

.homepage {
  .panel {
    background: $pale-pink;
    color: $orange;

    .cms-html {
      h3.phone-only {
        margin: 0 0 1.75em;

        @include desktop-and-tablet {
          display: none;
        }
      }

      p {
        line-height: 1.2;
      }
    }
  }
}
